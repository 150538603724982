/* .toolTipImagem {
  padding: 0px !important;
  margin-left: 160px !important;
} */

.logo {
  width: 120px;
  height: 53px;
  background-size: cover;
  margin-left: 10vw;
  margin-top: 5px;
  float: left;
}

#logoDefault {
  width: 173px;
  height: 72px;
}

.header {
  background: #fff !important;
  padding: 0px 30px !important;
  height: 80px !important;
}


.ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal {
  box-shadow: none;
  border-bottom: none;
  margin-top: 14px;
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal.menu-header {
  line-height: 64px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

#menu-header-icon-settings {
  font-size: 19px;
}

#menu-header-button-actions {
  font-size: 13px;
}

#menu-item-perfil {
  width: 100%;
  display: flex;
}

ul>a {
  text-decoration: none !important;
  line-height: 33px !important;
}

/* .question {
  float: right;
  top: 17px;
  right: 129px;
}

.erp-link {
  float: right;
  top: 17px;
  right: 144px; */
/* } */

/* .doc-yi {
  float: right;
  top: 17px;
  right: 159px;
}

.doc-cy {
  float: right;
  top: 17px;
  right: 174px;
}

.logout-bt {
  float: right;
  top: 17px;
  right: 0px;
} */

.profile-menu {
  background-color: #fff;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  padding: 10px;

}

.bt-header {
  image-orientation: left;
  margin-left: 20px;
}
