@import '~antd/dist/antd.less';

.ant-layout {
    background: #fff !important;
}

.spinCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}


.spinIsolate {
  position: fixed;
  left: 0;
  z-index: 2;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
  text-align: center;
}

.spinIsolate > span {
  top: 47.5%;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: fixed !important;
  max-height: none !important;
}

.ant-table-thead > tr > th {
  font-family: Raleway-Bold;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Regular.ttf'), url('assets/fonts/Raleway/Raleway-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway-Bold.ttf'), url('assets/fonts/Raleway/Raleway-Medium.ttf');
}

@font-face {
  font-family: 'Raleway-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway-Bold.ttf'), url('assets/fonts/Raleway/Raleway-Bold.ttf');
}

@font-face {
  font-family: 'Raleway-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway-SemiBold.ttf'), url('assets/fonts/Raleway/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: 'Neris-Light';
  font-size: normal;
  font-weight: normal;
  src: local('Neris-Light.otf'), url('assets/fonts/Neris/Neris-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Neris-SemiBold';
  font-size: normal;
  font-weight: normal;
  src: local('Neris-SemiBold.otf'), url('assets/fonts/Neris/Neris-SemiBold.otf') format('opentype');
}

.yh-table .ant-table-thead > tr:first-child > th:first-child, .yh-table .ant-table-tbody > tr > td:first-child {
  padding-left: 35px !important;
}

form.yh-form {
  padding: 0 33px;
}

.ant-modal-header, .ant-modal-footer {
  border: none;
}

.hide {
  display: none;
}
@hack: true; @import "theme.less";